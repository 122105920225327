<template>
    <div class="bg-white rounded-5 p-5">
        <div class="w-100 rounded-4">
            <div class="d-flex justify-content-between"
                style="background: rgb(241, 247, 255);border-radius: 27px 27px 0 0;">
                <h1 class="px-5 py-3 page-heading">RSS Build History</h1>
                <button class="text-button no-bg weight-600" style="margin-right: 10px;background: transparent;">
                    <router-link class="text-link margin-0" :to="{ name: 'RSSBuilder' }">
                        <img src="@/assets/px/add.png" alt="" style="height: 20px" /> Create new RSS Item
                    </router-link>
                </button>
            </div>
            <div v-if="rss_list && rss_list.length > 0" class="rss-list-table profile-listing-body px-2 pb-2"
                style="width: 82vw;">
                <b-table id="my-table" :items="rss_list.slice().reverse()" :fields="fields" :current-page="currentPage"
                    :per-page="perPage" bordered responsive>
                    <template #cell(s_no)="row">
                        {{ (currentPage - 1) * perPage + row.index + 1 }}
                    </template>
                    <template #cell(company_name)>
                        {{ (companyName) }}
                    </template>
                    <template #cell(feedtitle)="row">
                        <b-tooltip :target="`${row.index} feedtitle`" :title="row.item.feedtitle" triggers="hover" />
                        <span :id="`${row.index} feedtitle`">{{ row.item.feedtitle }}</span>
                    </template>
                    <template #cell(created_at)="row">
                        {{ formattedDate(row.item.created_at) }}
                    </template>
                    <template #cell(modified_at)="row">
                        {{ formattedDate(row.item.updated_at) }}
                    </template>
                    <template #cell(action)="row">
                        <div class="d-flex justify-content-center align-items-center" style="gap: 20px">
                            <ejs-tooltip class="tooltipcontainer" content="Edit" target="#target"
                                cssClass="customtooltip">
                                <router-link class="text-link margin-0"
                                    :to="{ name: 'RSSBuilder', query: { id: row.item.id } }">
                                    <img src="@/assets/px/edit-icon.png" alt="" style="height: 20px" id="target" />
                                </router-link>
                            </ejs-tooltip>
                            <ejs-tooltip class="tooltipcontainer" content="Delete" target="#target"
                                cssClass="customtooltip">
                                <img src="@/assets/px/delete-icon.png" alt="" style="height: 20px" id="target"
                                    @click="showDeleteAlertPopUp(row.item.id)" />
                            </ejs-tooltip>
                        </div>
                    </template>
                </b-table>
                <div class="b-table-pagination">
                    <div class="pagination-count-per-page">
                        <div class="pagination-number-per-page">
                            <div>
                                <span>Per page:</span>
                            </div>
                            <div>
                                <select style="" @change="changeCountPerPage" :value="perPage">
                                    <option v-for="item in perPageOptions" :key="item" :value="item">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <b-pagination align="right" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                        aria-controls="my-table" first-text="First" prev-text="Prev" next-text="Next"
                        last-text="Last"></b-pagination>
                </div>
            </div>
            <div class="w-100" v-else>
                <div class="d-grid justify-content-center align-items-center py-5">
                    <h3>No items created</h3>
                </div>
            </div>
            <div class="profile-delete-modal">
                <DeleteRSSModal v-if="showDeleteAlert" :open="showDeleteAlert" :indexTodelete="indexTodelete" @cancel="showDeleteAlert = false"
                    @handleDelete="handleDelete">
                </DeleteRSSModal>
            </div>
        </div>
        <div class="w-100">

        </div>
        <Loading v-if="loading" />
    </div>
</template>
<script>

import DeleteRSSModal from './delete_rss_modal';
import Loading from '@/components/general/loading/loading.vue';
import dayjs from '@/util/dayjs';
import { rssFeeds } from '../../util/apiRequests';
import { tryGetFilter } from '@/util/tryGetFilter';
export default {
    name: "RSSList",
    components: {
        DeleteRSSModal,
        Loading
    },
    data() {
        return {
            rss_list: null,
            showDeleteAlert: false,
            indexTodelete: null,
            fields: [
                { key: 's_no', label: 'S NO.', thStyle: { "padding-left": '0 !important' }, class: "table-col" },
                { key: 'company_name', label: 'Company Name', class: "table-col" },
                { key: 'feedtitle', label: 'Title', class: "table-col" },
                { key: 'created_at', label: 'Created Date & Time', class: "table-col" },
                { key: 'modified_at', label: 'Modified Date & Time', class: "table-col" },
                { key: 'action', label: 'Action', class: "action-sticky", stickyColumn: true, isRowHeader: true },
            ],

            // b-table properties
            storeKey: 'rssFeeds',
            perPageOptions: [5, 10, 20, 50, 100],
            currentPage: 1,
            perPage: 5,
            totalxRows: null,
            loading: true,
            companyName: null
        };
    },
    computed: {
        rows() {
            return this.totalRows;
        }
    },
    methods: {
        formattedDate(date) {
            return date ? dayjs(date).format('MM/DD/YYYY hh:mmA') : '';
        },
        changeCountPerPage({ target: { value } }) {
            this.perPage = Number(value);
        },
        savedPagination() {
            const previousPagination = tryGetFilter(this.$store, this.storeKey);
            this.currentPage = previousPagination.currentPage ?? 1;
            this.perPage = previousPagination.perPage ?? 5;
        },
        showDeleteAlertPopUp(id) {
            this.showDeleteAlert = true;
            this.indexTodelete = id;
        },
        async get_company() {
            let companyId = this.$store.state.user.company_id;
            const res = await this.$api.get(`/company/getCompany/${companyId}`);
            this.companyName = res.data.info[0].company_name;
        },
        async getRssList() {
            try {
                let list = await this.$api.get(rssFeeds.getRssList());
                let data = list.data.info;
                this.rss_list = data;
                this.totalRows = data.length;
            }
            catch (err) {
                this.$toasted.error("Failed to fetched Rss List");
            }
            finally {
                this.loading = false;
            }
        },
        async handleDelete() {
            try {
                await this.$api.delete(rssFeeds.deleteRssFeed(this.indexTodelete));
                this.showDeleteAlert = false;
                this.getRssList();
                this.$toasted.success('Item deleted successfully');

            } catch (e) {
                this.$toasted.error('Failed to delete item');
            }
        },
    },
    async created() {
        this.savedPagination();
        await this.get_company();
        await this.getRssList();
        this.loading = false;
    },
    watch: {
        currentPage: function (val) {
            this.$store.commit('filters/updateFilter', {
                stateKey: this.storeKey,
                criteria: {
                    perPage: Number(this.perPage),
                    currentPage: Number(val)
                }
            });
        },
        perPage: function (val) {
            this.$store.commit('filters/updateFilter', {
                stateKey: this.storeKey,
                criteria: {
                    currentPage: Number(this.currentPage),
                    perPage: Number(val)
                }
            });
        },
    },
}
</script>

<style>
.rss-list-table table tbody tr>td:first-child {
    padding-left: 2.5em !important;
}

.page-heading {
    font-weight: 700;
    border-radius: 27px 27px 0 0;
}

.b-table-sticky-column:last-child {
    right: 0;
    min-width: 100px;
}

.action-sticky {
    min-width: 150px;
    box-shadow: -10px 0px 10px 1px #aaaaaa;
}

.table-col {
    max-width: 150px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

/* ### Custom scroll ### */

/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ccc3c3;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--base-theme-color);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--base-theme-color);
}
</style>

<style scoped>
.text-button a,
.text-button a:hover,
.text-button:hover {
    background-color: #5057C3;
    color: white;
    padding: 10px;
    border-radius: 10px;
}
</style>