<template>
    <NoButtonModal :open="open" @close="$emit('cancel')">

        <button class="close-corner-x" type="button" @click.prevent="alertResponse('cancel')">
            <span class="material-icons-outlined dark-text">close</span>
        </button>

        <div v-if="!loading">
            <h1>Delete Alert</h1>
            <p>Are you sure you want to delete this Item?</p>
            <p v-if="indexTodelete && dashboardCount <= 1">This feed is linked with {{ dashboardCount }} dashboard </p>
            <p v-else-if="indexTodelete">This feed is linked with {{ dashboardCount }} dashboards </p>
            <button @click="alertResponse('cancel')" class="button secondary">Cancel</button>
            <button @click="alertResponse('handleDelete')" class="button primary">Delete</button>
        </div>

        <div v-if="loading" class="d-flex flex-column justify-content-center align-items-center loader-main"
            style="min-height: 12rem;">
            <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
            <span class="ms-3">Loading ...</span>
        </div>
    </NoButtonModal>
</template>

<script>
import NoButtonModal from '@/components/general/modals/NoButtonModal';
import { rssFeeds } from '../../util/apiRequests';

export default {
    name: 'DeleteRSSModal',
    components: { NoButtonModal },
    data() {
        return {
            loading: true,
            dashboardCount: 0,
        }
    },
    props: {
        open: {
            type: Boolean,
            required: true,
        },
        indexTodelete: {
            type: Number,
        }
    },
    methods: {
        alertResponse(action) {
            if (action === 'handleDelete') {
                this.$emit('handleDelete');
            }
            else {
                this.$emit('cancel');
            }
        },
        async getDashboardCount() {
            let response = await this.$api.get(rssFeeds.getDashboardCount(this.indexTodelete));
            if (response?.data?.DashBoardCount) {
                this.dashboardCount = response?.data?.DashBoardCount;
            }
        }
    },
    async created() {
        if (this.indexTodelete) {
            await this.getDashboardCount();
        }
        this.loading = false;
    }
};
</script>
